.field-label{
    display: block;
    padding-bottom: 4px;
    font-weight: 500;

    &.required::after {
        display: inline-block;
        margin-left: 2px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.sd-link-btn {
    &:extend(.ant-btn);
    padding: 4px 15px;
    border-radius: 5px;
    color: @white;
    &:hover{
        color: @white;
    }
}

.sd-phone-input {
    
    overflow: hidden;
    height: 40px;
        
    .PhoneInput {
        display: flex;
        width: 100%;
        align-items: center;

        .PhoneInputCountry {
            position: relative;
            align-self: stretch;
            display: flex;
            align-items: center;
            margin-right: 0.35em;
            

            .PhoneInputCountrySelectArrow {
                display: block;
                content: '';
                width: 0.3em;
                height: 0.3em;
                margin-left: 0.35em;
                border-style: solid;
                border-color: currentColor;
                border-top-width: 0;
                border-bottom-width: 1px;
                border-left-width: 0;
                border-right-width: 1px;
                transform: rotate(45deg);
                opacity: 0.45;
            }

            .PhoneInputCountrySelect {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                border: 0;
                opacity: 0;
                cursor: pointer;
            }

            .PhoneInputCountryIcon {
                width: calc(1em * 1.5);
                height: 1em;

                .PhoneInputCountryIconImg {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        & > input {
            border: none;
            padding: 0px !important;
            font-weight: 600;
            flex: 1;

            &::placeholder {
                font-size: 14px !important;
                font-weight: 600;
            }

            &:focus {
                outline: none;
            }
        }
    }

}

.table-actions {
    span {
        display: flex;
    }
}

.form_body {

    .ant-form-item-has-error {
        input{
            border-color: #ff4d4f !important;
        }
    }
}

.form-radio-group {
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-label > label {
        color: @tertiary-color;
        font-size: 14px !important;
        font-weight: 600;
        margin-bottom: 10px;
        &::after{
            content: "";
        }
    }

    .ant-radio-wrapper {

        span {
            color: @tertiary-color;
            font-size: 14px;
            
        }
    }

    &.ant-form-item{
        margin-bottom: 8px !important;
    }
}


.ant-btn.ant-btn-default.tag-buttons,
.ant-btn.tag-buttons  {
    height: auto;
    padding: 15px 8px;
    font-weight: 500;
    margin-right: 10px;
    margin-bottom: 8px;
    border-color: @dark-gray;

    &.ant-btn-primary {
        border-color: @primary-color;
    }
}

.simple-form-container{
    border-radius: 10px;
    padding: 30px;
    // max-width: 1100px;
    .avatar-uploader {
        .ant-upload {
            width: 100%;
            height: 142px;
            overflow: hidden;
        }

        .ant-upload-list-picture-card-container{
            position: absolute;
            top: 0;
            left: 0;

            margin: 0;
            width: 100%;
            height: 130px;

            .ant-upload-list-item-thumbnail img {
                object-fit: cover;
            }
            .ant-upload-list-item-actions {
                display: flex;
                align-items: center;

                >a {
                    margin-top: -4px;
                }
            }
        }
    }


    @media @max768 {
        padding: 20px;

        .ant-col {
            min-width: 142px;
        }
    }
}