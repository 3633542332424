//HEIGHT

.height-100 {
    height: 100%;
}

// WIDTH 
.max-width {
    width: @max-100;
}

.width-300 {
    width: @width-300;
}

.flex-flow-no-wrap{
    flex-flow: nowrap;
}

.mobile-header {
    max-width: 270px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// BORDER RADIUS 
.b-radius-20 {
    border-radius: @medium;
}

.b-radius-10 {
    border-radius: @tiny;
}

// PROFILE IMG 
.profile-img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #e7e7e7;
    color: @white;

    font-size: 24px;
    text-align: center;
}

// DISPLAY 
.d-block {
    display: block;
}
.visible-xs {
    display: none !important;
}
.d-i-block {
    display: inline-block;
}

// HEIGHT 
.max-height {
    height: @max-100;
}

.max-height-47 {
    height: 47%;
}

.icon-left {
    .ant-picker-suffix {
        position: absolute;
        margin: 0;
        left: 0;
        color: rgba(0, 0, 0, 0.85);
    }
    input {
        padding-left: 18px !important;
    }
}

.ant-form-item-has-error {
    .icon-left {
        .ant-picker-suffix {
            color: #ff4d4f;
        }
    }
}

.custom-tooltip {
    background: white;
    color: inherit;
    font-size: inherit;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
    padding: 5px 9px;
}

.icon-container{
    border-radius: 5px;
    padding: 8px;
}

// DYNAMIC INFO MODAL 
.dynamic-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        margin: 0;
        font-weight: 600;
    }
    p{
        text-align: center;
        // margin: @medium-20-0;
    }

    button[class="ant-btn"]{
        border: none;
        box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
        color: @secondary-color;
    }

    .schedule-info {
        .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
            height: 50px;
            overflow: hidden;
        }
    }
}

// DYNAMIC FORM MODAL 
.dynamic-form-modal{
    display: flex;
    flex-direction: column;

    .ant-input-affix-wrapper > input.ant-input {
        color: @primary-color;

        &::selection {
            color: @white;
        }

        &::-moz-selection {
            color: @white;
        }
    }

    .ant-input-prefix {
        font-weight: 600;
        color: @primary-color;
    }

    .no-title{
        padding-top: 30px;
    }
    
    .ant-modal-header > .ant-modal-title {
        margin: 0;
        font-weight: 600;
        text-align: left;
        font-size: 20px;
        color: @secondary-color;
    }

    
    p{
        text-align: center;
        margin: @medium-20-0;
    }

    .description {
        color: @dark-gray;
    }


}


.dynamic-dialog-modal {

    .experience-details {

        .ant-card {
            border: none;
            border-radius: @border-radius-default;
    
            .merchant-image {
                border-radius: @border-radius-default;
            }
    
            .header {
                margin-bottom: 8px;
                color: @secondary-color;
            }
    
            .experience-info {
    
                .label,
                .info {
                    font-size: 14px;
                }
    
                .label {
                    margin-right: 12px;
                    margin-bottom: @label-info-bottom-margin;
                    color: @secondary-color;
                }
    
                .info {
                    color: @dark-gray;
                    margin-bottom: @label-info-bottom-margin;
                    font-weight: 600;
    
                    span.label {
                        color: @primary-color;
                        font-weight: 600;
                    }
                }
            }
    
            .experience-tags {
                padding: 5px 10px;
                background: @primary-color;
                color: @white;
                font-weight: 600;
            }
        }
    
        .sd-pagination {
            margin-top: 50px;
        }
    }

}


.filter-view-container {
    height: 500px;
    
    @media @max768{
        height: unset;
    }
    .filter-view-form {
        padding-bottom: 25px;
    }

    .header {
        font-size: 18px;
        font-weight: 600;
        color: @white;
    }

    .sd-simple-form {

        .form-col-title {
            color: @white;
            margin-bottom: 2rem;
        }

        .form-col-subtitle {
            color: @dark-gray;
            font-style: italic;
        }

        .ant-form-item-label > label, .form-custom-label{
            color: @white;
            font-weight: 600;
            font-size: 16px !important;
            &::before {
                content: "" !important;
            }
        }
    }
}


// BACKGROUND
.bg-primary {
    background-color: @primary-color;
}

.bg-secondary {
    background-color: @secondary-color;
}

.bg-tertiary {
    background-color: @tertiary-color;
}

.bg-dark-gray {
    background-color: @dark-gray;
}

.bg-peach {
    background-color: @peach;
}

.bg-green {
    background-color: @green;
}

.bg-white{
    background-color: @white;
}

// SHADOW 
.@{s-var} {
    @{s-var}: @shadow;
}

// PADDING
.padding-s {
    padding: @tiny;
}
.padding-s-v {
    padding: @tiny 0;
}
.pad-bottom-s {
    padding-bottom: @tiny;
}
.padding-m {
    padding: @medium;
}

.padding-m-v {
    padding: @medium 0;
}
.pad-bottom-s-s{
    padding-bottom: @vtiny
}

// MARGIN
.r-margin {
    margin: 0 !important;
}
.margin-top-s {
    margin-top: @tiny;
}
.margin-bottom-s {
    margin-bottom: @tiny;
}
.margin-top-m {
    margin-top: @medium;
}
.margin-bottom-m {
    margin-bottom: @medium;
}
.margin-m-v {
    margin: @medium-20-0;
}

//MARGIN RIGHT 
.margin-right-s{
    margin-right: @tiny;
}
.margin-auto-r {
    margin-right: auto;
}

// MARGIN LEFT 
.space-left-s-xs {
    margin-left: @tiny;
}
.margin-auto-l {
    margin-left: auto;
}

// GAP/GRID-GAP
.gap-flex {
    gap: @medium !important;
}
.gap-10 {
    gap: 10px !important
}
.gap-20 {
    gap: 20px !important
}

// FONT
.font-11 {
    font-size: 11px;
}
.font-12{
    font-size: 12px;
}
.font-13{
    font-size: 13px;
}
.font-14{
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-24 {
    font-size: 24px;
}
.font-26 {
    font-size: 30px;
}
.font-30 {
    font-size: 30px;
}
.font-60 {
    font-size: 60px;
}
.fw-600 {
    font-weight: 600;
}
.fw-400 {
    font-weight: 450;
}
// COLOR
.text-primary {
    @{c-var}: @primary-color;
}
.text-secondary {
    color: @secondary-color;
}
.text-tertiary {
    color: @tertiary-color;
}
.text-blue {
    color: @blue;
}
.text-white {
    color: @white;
}
.text-error {
    color: @red;
}

.link-secondary,
.text-gray {
    color: @dark-gray;
}
.link-primary:hover{
    @{c-var}: @primary-color;
}

.link-secondary:hover {
    @{c-var}: @primary-color;
}

// TEXT DECORATION/TRANSFORM
.text-underline {
    text-decoration: underline;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}

// ALIGNMENT 
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-justify{
    text-align: justify;
}
// CURSOR 
.c-pointer {
    cursor: pointer;
}

.c-disabled {
    cursor: not-allowed;
}

.tableClass {
    th {
       color: @primary-color;
    }
 }
 .tableClassOne{
    & thead > tr > th {
      color: 'pink';
    }
 }

 @media @max768 {
     .visible-xs {
         display: block !important;
     }

     .max-width-xs {
         width: 100%;
     }

     .d-header,
     .hidden-xs {
         display: none;
     }

    .mobile-add {
        position: fixed;
        right: 14px;
        bottom: 14px;
        width: 50px !important;
        height: 50px !important;
        z-index: 1;
    }

    .space-top-m-xs {
        margin-top: 20px
    }

    .space-left-s-xs {
        margin-left: 0;
    }

    .action-container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        * {
            width: 100%;
            margin: 0 !important;
            padding: 0;
        }
    }
 }


 @media @max1280 {
    .visible-lg {
        display: block !important;
    }
    .hidden-lg {
        display: none !important;
    }
}

.horizontal-scroll-container {

    .horizontal-scroll-wrapper {
        flex-wrap: nowrap;
        overflow-x: hidden;
        padding: 0px 25px;
        scroll-behavior: smooth;

        @media @max768 {
            padding: 0px;
            overflow-x: scroll;
        }

        .arrow-left {
            position: absolute;
            left: 0;
            bottom: 6%;
            height: 100%;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            color: @dark-gray;
            font-size: 36px;
            z-index: 2;
            width: 25px;

            @media @max768 {
                display: none;
            }
        }

        .arrow-right {
            position: absolute;
            right: 0;
            bottom: 6%;
            height: 100%;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            color: @dark-gray;
            font-size: 36px;
            z-index: 2;
            width: 25px;

            @media @max768 {
                display: none;
            }
        }
    }
    

    .scroll-title {
        color: @white !important;
        margin: 2px;
        margin-bottom: 30px;

        @media @max576 {
            font-size: 16px;
        }

        @media @max320 {
            font-size: 14px;
        }
    }

    
    

    .ant-card-hoverable {
        border-radius: 10px;
        overflow: hidden;
        transition: box-shadow 0.3s, border-color 0.3s, background-color 0.3s;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        // max-width: 280px;
        height: 350px;
        // max-height: 350px;
        width: 270px;
        border: none;

        @media @max1399 {
            height: 300px;
            width: 240px;
        }

        @media @lg-point {
            height: 250px;
            width: 210px;
        }

        @media @max1080 {
            height: 250px;
            width: 190px;
        }

        @media @max991 {
            height: 220px;
            width: 170px;
        }

        @media @max899 {
            height: 200px;
            width: 170px;
        }

        @media @max768 {
            height: 350px;
            width: 270px;
        }

        @media @max689 {
            height: 300px;
            width: 240px;
        }

        @media @max619 {
            height: 280px;
            width: 210px;
        }

        @media @max576 {
            height: 250px;
            width: 190px;
        }

        @media @max449 {
            height: 200px;
            width: 140px;
        }

        .group-title {
            color: @white;
            font-weight: 300;

            &.name {
                color: @white;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
            }
            &.date {
                color: @white;
                font-size: 24px;
                font-weight: 600;
                margin: 0;

                @media @lg-point {
                    font-size: 18px;
                }

                @media @max449 {
                    font-size: 14px !important;
                }
            }
        }

        .status-container {
            position: absolute;
            top: 15px;
            left: 15px;

            

            .status-tag {
                border: none;

                &.text-white {
                    color: @white;
                }

                &.bg-dark-gray {
                    background-color: @dark-gray;
                }

                &.bg-primary {
                    background-color: @primary-color;
                }

                &.bg-green {
                    background-color: @green;
                }

                &.bg-secondary {
                    background-color: @secondary-color;
                }

                &.bg-red {
                    background-color: red;
                }

               
            }
            @media @max426 {
                top: 10px;
                left: 10px;
            }

            @media @max320 {
                top: 8px;
                left: 8px;

                .status-tag {
                    font-size: 10px;
                }
            }
        }

        @media @max768 {
            .group-title {
                font-size: 14px;

                &.name {
                    font-size: 14px;
                }

                &.date {
                    font-size: 18px;
                }
            }
        }


        @media @max576 {
            height: 250px;
            max-height: 250px;

            .ant-card-body {
                padding: 15px 10px
            }
        }

        @media @max426 {
            height: 200px;
            max-height: 200px;

            

            .group-title {
                font-size: 14px;

                &.name {
                    font-size: 14px;
                }

                &.date {
                    font-size: 16px;
                }
            }
        }

        @media @max320 {
            height: 150px;
            max-height: 150px;

            .ant-card-body {
                padding: 12px;
            }

            .group-title {
                font-size: 12px;

                &.name {
                    font-size: 12px;
                }

                &.name {
                    font-size: 16px;
                }
            }
        }

        .ant-card-cover {
            img {
                height: calc(390px * 0.80);
            }
        }

        .ant-card-meta-title {
            color: @secondary-color;
            font-weight: 900;
            transition: color 0.3s;
        }

        &:hover {
            background-color: @primary-color;
            border-color: @primary-color;

            .ant-card-meta-title {
                font-weight: 900;
                color: @white;
            }
        }

        
        
    }

    @media @max768{
        justify-content: center;
    }
}

//prefix
.prefix-icon{
    color: #6930a3;
    font-weight: 900;
    font-size: 14px;
}