

@line-height: 1.5em;
@line-count: 5;
@window-width: 100vw;
@window-height: 100vh;
@icon-size: 24px;
@icon-container-size: 40px;
.ant-layout-content {
    overflow-x: clip;

    .swipeable-stacks-container-v2{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 100%;
        max-height: 600px;
        width: 80vw !important;
        // height: 70vh !important;

        @media @max400ANDmaxH900 {

        }

        @media @max280 {
            // height: 55vh !important;
            width: 85vw !important;
        }

        //Card Item Styles
        .activity-card {

            max-width: 360px;
            max-height: 600px;
            width: 80vw !important;
            height: 70vh !important;

            @media @max768 {
                height: 55vh !important;
                width: 75vw !important;
            }

            @media @max576 {
                height: 50vh !important;
                width: 75vw !important;
            }

            @media @max426 {
                height: 50vh !important;
                width: 75vw !important;
            }

            @media @max280 {
                height: 45vh !important;
                width: 80vw !important;
            }

            @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */ 

                // This media query is used for: iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X
                // @media only screen and (width: 375px) 
                // and (height: 812px) 
                // and (-webkit-device-pixel-ratio: 3) { 
                //     height: 100vh !important;
                //     width: 80vw !important;
                // }

            }

            

            
            
            // width: @window-width * 0.75;
            // height: @window-height * 0.5;
            // height: 100%;
            border-radius: 25px;
            box-shadow: @shadow;
            overflow: hidden;
            border: none;


            .ant-card-body {
                padding: 0;
                height: 100%;
    
                .title,
                .description {
                    margin: 0;
                }
    
                .title {
                    color: @white !important;
                    font-size: 1.15rem;
                    margin-bottom: 8px;
                }
    
                .description {
                    color: @dark-gray !important;
                    font-size: 0.9rem;
                    margin-bottom: 12px;
                }
    
                .full-description {
                    color: @dark-gray !important;
                    font-weight: 400;
                    overflow: hidden;
                    position: relative;
                    line-height: @line-height;
                    max-height: @line-height * @line-count; 
                    text-align: start;
                    margin-right: -1em;
                    padding-right: 1em;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    font-size: 20px;
                    white-space: pre-wrap;
                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        width: 1em;
                        height: 1em;
                        margin-top: 0.2em;
                        background: transparent;
                    }
                }
    
                .more-info {
                    color: @primary-color !important;
                    font-size: 14px;
                    font-weight: 600;
                }
    
                .discount-info-container {
                    position: absolute;
                    right: 10px;
                    top: 10px;
    
                    .discount-info {
                        margin: 0;
                        padding: 5px 15px;
                        color: @white;
                        background-color: red;
                        border-radius: 5px;
                    }
                }
    
                .activity-image {
                    min-height: 200px;
                    height: 100%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    justify-content: stretch;
                    align-items: flex-end;
                    padding:  0 8px 8px;
                    position: 'relative';
                    background-size: cover !important;
                    background-position: center !important;

                    @media @max280 {
                        min-height: 100px;
                    }

                    // align-items: flex-end;
                    // border: 1px solid red;
                    .discount-info-container {
                        position: absolute;
                        right: 10px;
                        top: 10px;
    
                        .discount-info {
                            margin: 0;
                            padding: 5px 15px;
                            color: @white;
                            background-color: red;
                            border-radius: 5px;
                        }
                    }
    
                    .voucher-title {
                        color: @white;
                        font-size: 12px;
                        text-align: center;
                        margin-bottom: 0;
                    }
    
                    .voucher-info {
                        color: @white;
                        font-size: 20px;
                        text-align: center;
                        margin-bottom: 0;
                        margin-top: 10px;
    
                        &.default {
                            font-weight: 400;
                        }
                    }
    
                    .divider {
                        height: 25px;
                        border-left: 1px solid @dark-gray;
                        opacity: 0.5;
                        margin-bottom: 10px;
                        // width: 2px;
                    }
                }
            }
        }
        
        .actions-container {
            padding: 15px 20px;
            border-radius: 50px;
            background-color: @secondary-color;
            box-shadow: @shadow;
    
            .action-divider {
                border-left: 1px solid @dark-gray;
                opacity: 0.5;
                height: 1.9em;
            }

            .anticon.anticon-reload.action-item {
                font-size: @icon-size;
                font-weight: 600;
                color: @white;
                
                svg {
                    -moz-transform: scale(-1, -1);
                    -o-transform: scale(-1, -1);
                    -webkit-transform: scale(-1, -1);
                    transform: scale(-1,-1) translateY(2px);
                }
            }

            .anticon.anticon-check.action-item {
                font-size: @icon-size;
                font-weight: 600;
                color: @white;

                svg {
                    transform: translateY(4px);
                }
            }

            .anticon.anticon-close.action-item {
                font-size: @icon-size;
                font-weight: 600;
                color: @white;

                // svg {
                //     transform: translateY(-1px);
                // }
            }
    
            .icon-container {
                background-color: @white;
                border-radius: 35px;
                box-shadow: @shadow;
                width: @icon-container-size;
                height: @icon-container-size;
                display: flex;
                justify-content: center;
                align-items: center;
                

                &.close {
                    background-color: @red;
                    cursor: pointer;
                }

                &.check {
                    background-color: @green;
                    cursor: pointer;
                }

                &.redo {
                    background-color: @gray;
                    cursor: pointer;
                }
            }
    
            .ant-space-item {
    
                &:nth-child(1) > .icon-container > .inner > span {
                    margin-top: 5px;
                    padding: 0 3px;
                }
    
                &:nth-child(3) > .icon-container > .inner > span {
                    margin-bottom: 5px;
                    padding: 0 3px;
                }
            }
        }
    }

    .swipeable-stacks-container{

        //Card Item Styles
        .activity-card {

            width: 325px;
            height: 380px;

            // @media @max426 {
            //     width: 250px;
            //     height: 280px;
            // }
            
            // width: @window-width * 0.75;
            // height: @window-height * 0.5;
            // height: 100%;
            border-radius: @border-radius-default;
            box-shadow: @shadow;
            overflow: hidden;
            border: none;
            .ant-card-body {
                padding: 0;
                height: 100%;
    
                .title,
                .description {
                    margin: 0;
                }
    
                .title {
                    color: @secondary-color !important;
                    font-size: 1.15rem;
                    margin-bottom: 8px;
                }
    
                .description {
                    color: @primary-color !important;
                    font-size: 0.9rem;
                    margin-bottom: 12px;
                }
    
                .full-description {
                    color: @dark-gray !important;
                    font-weight: 400;
                    overflow: hidden;
                    position: relative;
                    line-height: @line-height;
                    max-height: @line-height * @line-count; 
                    text-align: justify;
                    margin-right: -1em;
                    padding-right: 1em;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    white-space: pre-wrap;
                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        width: 1em;
                        height: 1em;
                        margin-top: 0.2em;
                        background: transparent;
                    }
                }
    
                .more-info {
                    color: @primary-color !important;
                    font-size: 14px;
                    font-weight: 600;
                }
    
                .discount-info-container {
                    position: absolute;
                    right: 10px;
                    top: 10px;
    
                    .discount-info {
                        margin: 0;
                        padding: 5px 15px;
                        color: @white;
                        background-color: red;
                        border-radius: 5px;
                    }
                }
    
                .activity-image {
                    min-height: 200px;
                    height: 43%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    display: flex;
                    justify-content: stretch;
                    align-items: flex-end;
                    padding:  0 8px 8px;
                    position: 'relative';
                    background-size: cover !important;
                    background-position: center !important;
                    // align-items: flex-end;
                    // border: 1px solid red;

                    @media @max280 {
                        min-height: 100px;
                    }

                    .discount-info-container {
                        position: absolute;
                        right: 10px;
                        top: 10px;
    
                        .discount-info {
                            margin: 0;
                            padding: 5px 15px;
                            color: @white;
                            background-color: red;
                            border-radius: 5px;
                        }
                    }
    
                    .voucher-title {
                        color: @white;
                        font-size: 12px;
                        text-align: center;
                        margin-bottom: 0;
                    }
    
                    .voucher-info {
                        color: @white;
                        font-size: 20px;
                        text-align: center;
                        margin-bottom: 0;
                        margin-top: 10px;
    
                        &.default {
                            font-weight: 400;
                        }
                    }
    
                    .divider {
                        height: 25px;
                        border-left: 1px solid @dark-gray;
                        opacity: 0.5;
                        margin-bottom: 10px;
                        // width: 2px;
                    }
                }
            }
        }
    
        .actions-container {
            padding: 15px 20px;
            border-radius: 50px;
            background-color: @secondary-color;
            box-shadow: @shadow;
    
            .action-divider {
                border-left: 1px solid @dark-gray;
                opacity: 0.5;
                height: 1.9em;
            }

    
            .icon-container {
                background-color: @white;
                border-radius: 35px;
                box-shadow: @shadow;
            }
    
            .ant-space-item {
    
                &:nth-child(1) > .icon-container > .inner > span {
                    margin-top: 5px;
                    padding: 0 3px;
                }
    
                &:nth-child(3) > .icon-container > .inner > span {
                    margin-bottom: 5px;
                    padding: 0 3px;
                }
            }
        }
    }

    .actions-container {
        padding: 15px 20px;
        border-radius: 50px;
        background-color: @secondary-color;
        box-shadow: @shadow;

        .action-divider {
            border-left: 1px solid @dark-gray;
            opacity: 0.5;
            height: 1.9em;
        }

        .anticon.anticon-reload.action-item {
            font-size: @icon-size;
            font-weight: 600;
            color: @white;
            
            svg {
                -moz-transform: scale(-1, -1);
                -o-transform: scale(-1, -1);
                -webkit-transform: scale(-1, -1);
                transform: scale(-1,-1) translateY(2px);
            }
        }

        .anticon.anticon-check.action-item {
            font-size: @icon-size;
            font-weight: 600;
            color: @white;

            svg {
                transform: translateY(4px);
            }
        }

        .anticon.anticon-close.action-item {
            font-size: @icon-size;
            font-weight: 600;
            color: @white;

            // svg {
            //     transform: translateY(-1px);
            // }
        }

        .icon-container {
            background-color: @white;
            border-radius: 35px;
            box-shadow: @shadow;
            width: @icon-container-size;
            height: @icon-container-size;
            display: flex;
            justify-content: center;
            align-items: center;
            

            &.close {
                background-color: @red;
                cursor: pointer;
            }

            &.check {
                background-color: @green;
                cursor: pointer;
            }

            &.redo {
                background-color: @gray;
                cursor: pointer;
            }
        }

        .ant-space-item {

            &:nth-child(1) > .icon-container > .inner > span {
                margin-top: 5px;
                padding: 0 3px;
            }

            &:nth-child(3) > .icon-container > .inner > span {
                margin-bottom: 5px;
                padding: 0 3px;
            }
        }
    }
}